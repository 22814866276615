import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Nav from "./Components/Nav";
import Section3D from "./Components/3Dsection";
import Intro from "./Components/Intro";
import Games from "./Components/Games";
import Footer from "./Components/Contact";
import Donations from "./Components/Donation";
import Biblia from "./Components/Bible";
import { LanguageProvider } from "./context/LanguageContext";

function App() {
  return (
    <LanguageProvider>
      <Router>
        <div className="App min-h-screen bg-black flex flex-col">
          {/* Rutas principales con Nav */}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Nav />
                  <div id="home">
                    <Section3D />
                  </div>
                  <div id="about">
                    <Intro />
                  </div>
                  <div id="games">
                    <Games />
                  </div>
                
                  <div className="bg-gradient-to-r from-gray-800 via-gray-900 to-black" id="Biblia">
                    <Biblia />
                  </div>
                  <div id="Donations">
                    <Donations />
                  </div>
                  <div id="Contact">
                    <Footer />
                  </div>
                </>
              }
            />
            {/* Ruta exclusiva para el juego */}
            
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;