import React, { useEffect, useState,useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getDocs, collection } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../services';
import { FiDownload, FiStar, FiClock } from 'react-icons/fi';
import { LanguageContext } from '../context/LanguageContext';


const Games = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useContext(LanguageContext);

  // Animaciones
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 100 }
    },
    hover: {
      y: -5,
      scale: 1.02,
      transition: { duration: 0.3 }
    },
    tap: { scale: 0.98 }
  };

  const imageVariants = {
    hover: { scale: 1.05 },
    rest: { scale: 1 }
  };

  useEffect(() => {
    getDocs(collection(db, 'games')).then((response) => {
      const games = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(games);
      setIsLoading(false);
    });
  }, []);

  const handleDownload = (filePath) => {
    const fileRef = ref(storage, filePath);

    getDownloadURL(fileRef)
      .then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filePath;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      });
  };

  if (isLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900"
      >
        <div className="flex flex-col items-center gap-4">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
            className="w-12 h-12 border-4 border-t-transparent border-blue-400 rounded-full"
          />
          <p className="text-blue-200 font-medium">Loading...</p>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900 p-6 lg:p-12"
    >
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, type: 'spring' }}
        className="text-5xl md:text-6xl font-bold text-center mb-12 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent"
      >
        {t("GameLibrary")}
      </motion.h1>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8"
      >
        <AnimatePresence>
          {products.map((game) => (
            <motion.div
              key={game.id}
              variants={cardVariants}
              whileHover="hover"
              whileTap="tap"
              className="group relative overflow-hidden rounded-2xl bg-white/10 backdrop-blur-lg border border-white/20 shadow-2xl"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              
              <div className="relative z-10 p-6">
                {/* Encabezado con metadatos */}
                <div className="flex items-center gap-4 mb-4 text-blue-200">
                  {game.rating && (
                    <div className="flex items-center gap-1">
                      <FiStar className="text-yellow-400" />
                      <span>{game.rating}</span>
                    </div>
                  )}
                  {game.duration && (
                    <div className="flex items-center gap-1">
                      <FiClock />
                      <span>{game.duration}h</span>
                    </div>
                  )}
                </div>

                {/* Imagen con efecto hover */}
                <motion.div
                  variants={imageVariants}
                  className="relative overflow-hidden rounded-xl aspect-video"
                >
                  <img
                    src={game.image}
                    alt={game.name}
                    className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                </motion.div>

                {/* Contenido del juego */}
                <div className="mt-6">
                  <h3 className="text-xl font-bold text-white mb-2">
                    {game.name}
                  </h3>
                  <p className="text-blue-100 text-sm line-clamp-3 mb-4">
                    {game.description}
                  </p>

                  {/* Botón de descarga mejorado */}
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleDownload(game.zipPath)}
                    className="w-full flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-400 hover:to-purple-400 text-white px-6 py-3 rounded-lg transition-all duration-300"
                  >
                    <FiDownload className="text-lg" />
                    <span>Download for Windows</span>
                  </motion.button>
                </div>
              </div>

              {/* Efecto de brillo al hover */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="absolute -inset-1 bg-gradient-to-r from-blue-500/30 to-purple-500/30 blur-xl" />
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default Games;